import React from "react";
import { Link, graphql } from "gatsby";
import { Seo } from "../components/Seo";
import { Header, Footer } from "../components/layout/index";
import { BannerImage } from "../components/atoms/BannerImage";
import { truncateBody } from "../utils";
import { infoBaseUrl } from "../constants";

const InfoIndex = ({ data }) => {
  const infos = data.info.nodes;

  return (
    <>
      <Seo title="Toutes les informations - Téléexpertise" />
      <Header />
      <div className="bg-gray-50">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <h1 className="text-3xl tracking-tight font-bold text-gray-900 sm:text-4xl">
            Informations
          </h1>
          <div className="mt-12">
            <div className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-12 lg:grid-cols-3">
              {infos.map(({ title, slug, id, description, body }) => {
                const excerpt = truncateBody(body, 141);
                return (
                  <div key={id}>
                    <Link to={`${infoBaseUrl}${slug.current}/`}>
                      <h2 className="text-lg leading-6 font-medium text-gray-900">
                        {title}
                      </h2>
                      <p className="mt-2 text-base text-gray-500">
                        {description || excerpt}
                      </p>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <BannerImage />
      <Footer />
    </>
  );
};

export default InfoIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    info: allSanityInfo(sort: { fields: priority, order: ASC }) {
      nodes {
        id
        title
        slug {
          current
        }
        body: _rawBody
        description
        publishedAt(formatString: "DD MMMM YYYY", locale: "fr")
      }
    }
  }
`;
